@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

:root {

  /* Blue, Light Blue, Yellow */

  --theme-1: #095b93;
  --theme-2: #489AC2;
  --theme-3: #ffffff;
  --theme-4: #414141;


  /* Minty? */
  /* --theme-1: #00b188;
  --theme-2: #231f20;
  --theme-3: #D9D9D9;
  --theme-4: #345160; */

}

@keyframes launchAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Exo', sans-serif;
  background-color: var(--theme-2);
  animation: launchAnimation 1s ease-in forwards;
}
